import { gql } from "@apollo/client";
import React from "react";

export const EventSubjectLabelFragment = gql`
  fragment EventSubjectLabelFragment on Event {
    id
    gamePlayer {
      id
      playerNumber
    }
    person {
      id
      shortNameWithAnonymisation
    }
    createdByPerson {
      id
      shortNameWithAnonymisation
    }
    team {
      id
      name
    }
    eventView {
      avatarUrl
    }
  }
`;

export default function EventSubjectLabel({ event }) {
  return (
    // Hiding player number because it is already displayed as the avatar if the player does not have a profile photo.
    <>
      {event?.person?.shortNameWithAnonymisation ||
        event?.team?.name ||
        event?.createdByPerson?.shortNameWithAnonymisation}
      {event?.eventView?.avatarUrl && event?.gamePlayer?.playerNumber && <> #{event.gamePlayer.playerNumber}</>}
    </>
  );
}
