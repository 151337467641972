import { gql } from "@apollo/client";
import VideoPlayer from "atoms/VideoPlayer";
import { VIDEO_PAGE_SKIPPING_SECONDS } from "helpers/constants";
import { modalContext } from "helpers/contexts";
import { gaSetSharedEventParams } from "helpers/gtag";
import mergeRefs from "helpers/mergeRefs";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useGotoUrl from "helpers/useGotoUrl";
import { useVideoVolumn } from "helpers/useVideoVolumn";
import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useComponentSize } from "react-use-size";
import NotFoundPage from "shared/NotFoundPage";
import PageTitle from "shared/PageTitle";

import EVENT_TYPE_FILTERS from "./video/EVENT_TYPE_FILTERS";
import HighlightHub, { HighlightHubFragment } from "./video/HighlightHub";
import useFilteredEvents, { useFilteredEventsFragment } from "./video/useFilteredEvents";
import VideoActivePlayerStats from "./video/VideoActivePlayerStats";
import VideoBoxScoreButton from "./video/VideoBoxScoreButton";
import VideoControls, { VideoControlsFragment } from "./video/VideoControls";
import VideoEventNavShortcutHandler, {
  VideoEventNavShortcutHandlerFragment,
} from "./video/VideoEventNavShortcutHandler";
import VideoEvents, { VideoEventsFragment } from "./video/VideoEvents";
import VideoEventsFilter, { VideoEventsFilterFragment } from "./video/VideoEventsFilter";
import VideoHeader, { VideoHeaderFragment } from "./video/VideoHeader";
import VideoNewHighlightButtons, { VideoNewHighlightButtonsFragment } from "./video/VideoNewHighlightButtons";
import VideoPageLayout from "./video/VideoPageLayout";
import VideoPotwCompetitionButton from "./video/VideoPotwCompetitionButton";
import VideoShortcutsButton from "./video/VideoShortcutsButton";
import VideoSkipControl from "./video/VideoSkipControl";
import VideoTourButton, { VideoTourButtonFragment } from "./video/VideoTourButton";

export const videoPageContext = createContext();

export default function VideoPage({ gameId }) {
  const videoSize = useComponentSize();
  useEnforceAuth({ enforceAuthenticated: true });
  const videoPageRef = useRef();
  const videoRef = useRef();
  const sidebarRef = useRef();
  const floatingControlsRef = useRef();
  const [duration, durationSet] = useState(null);
  const [dismissedPromptStrings, dismissedPromptStringsSet] = useState([]);
  const [currentTime, currentTimeSet] = useState(0);
  const [playing, playingSet] = useState(false);
  const [buffering, bufferingSet] = useState(false);
  const [audioEnabled, audioEnabledSet] = useState(true);
  const [videoVolume] = useVideoVolumn();

  const [permissionData] = useData(
    gql`
      query VideoPage_permission($gameId: ID!) {
        game(id: $gameId) {
          id
          canOnsenAccessVideo
        }
      }
    `,
    { gameId },
  );

  const [data] = useData(
    gql`
      query VideoPage($gameId: ID!) {
        game(id: $gameId) {
          id
          canOnsenAccessVideo
          videoHlsUrl
          ...VideoHeaderFragment
          ...VideoControlsFragment
          ...VideoEventsFragment
          ...VideoEventsFilterFragment
          ...useFilteredEventsFragment
          ...VideoNewHighlightButtonsFragment
          ...HighlightHubFragment
          ...VideoTourButtonFragment
          ...VideoEventNavShortcutHandlerFragment
          club {
            id
          }
          events {
            id
            startAtSeconds
            finishAtSeconds
          }
          periods {
            number
            startAtSeconds
          }
          league {
            id
            sport
          }
          currentUserDefaultGamePlayer {
            id
            participation {
              id
              isHomeTeam
            }
          }
        }
      }
      ${VideoHeaderFragment}
      ${VideoControlsFragment}
      ${VideoEventsFragment}
      ${VideoEventsFilterFragment}
      ${useFilteredEventsFragment}
      ${VideoNewHighlightButtonsFragment}
      ${HighlightHubFragment}
      ${VideoTourButtonFragment}
      ${VideoEventNavShortcutHandlerFragment}
    `,
    { gameId },
  );
  gaSetSharedEventParams({ club_id: data?.game?.club?.id });

  useEffect(() => {
    videoRef.current.volume = videoVolume / 100;
  }, [videoVolume]);

  // filters
  const availableEventTypeFilters = EVENT_TYPE_FILTERS.filter(
    (filter) => !filter.sport || data?.game?.league.sport === filter.sport,
  );
  const [homeFilterPersonId, homeFilterPersonIdSet] = useState(null);
  const [awayFilterPersonId, awayFilterPersonIdSet] = useState(null);
  const [activeEventTypeFilters, activeEventTypeFiltersSet] = useState(availableEventTypeFilters); // ALL

  useLayoutEffect(() => {
    if (data?.game?.currentUserDefaultGamePlayer) {
      if (data.game.currentUserDefaultGamePlayer.participation.isHomeTeam) {
        homeFilterPersonIdSet("ALL");
      } else {
        awayFilterPersonIdSet("ALL");
      }
    }
  }, [!data]);
  const filteredEventIds = useFilteredEvents({
    game: data?.game,
    activeEventTypeFilters,
    homeFilterPersonId,
    awayFilterPersonId,
  });

  // active event
  let activeEventId = null;
  for (const event of data?.game?.events || []) {
    if (!filteredEventIds.includes(event.id)) continue;

    // already finished
    if (event.finishAtSeconds < currentTime) continue;

    // started and not overlapping previous event
    if (event.startAtSeconds <= currentTime) {
      activeEventId = event.id;
    }
  }

  const gotoUrl = useGotoUrl();

  // redirect back to game overview page if video is disabled or no permission
  const redirectBack = !!data?.game && !data?.game?.videoHlsUrl;
  useLayoutEffect(() => {
    if (redirectBack) {
      gotoUrl(`/game/${gameId}/overview`, { replace: true });
    }
  }, [redirectBack]);

  const currentPeriodNumber = data?.game?.periods.findLast((p) => p.startAtSeconds <= currentTime)?.number || 1;

  const { isModalActive } = useContext(modalContext);

  if (permissionData && !permissionData.game?.canOnsenAccessVideo) return <NotFoundPage />;

  return (
    <videoPageContext.Provider
      value={{
        isVideoPageModalActive: isModalActive,
        gameId,
        filteredEventIds,
        activeEventTypeFilters,
        activeEventTypeFiltersSet,
        availableEventTypeFilters,
        duration,
        currentTime,
        currentTimeSet,
        currentPeriodNumber,
        activeEventId,
        playing,
        buffering,
        floatingControlsRef,
        sidebarRef,
        videoRef,
        videoPageRef,
        audioEnabled,
        homeFilterPersonId,
        homeFilterPersonIdSet,
        awayFilterPersonId,
        awayFilterPersonIdSet,
        dismissedPromptStrings,
        dismissedPromptStringsSet,
      }}
    >
      <PageTitle title="Game Video" />
      <VideoEventNavShortcutHandler game={data?.game} />
      <VideoPageLayout
        componentRef={videoPageRef}
        header={
          <>
            <VideoHeader game={data?.game} />
            <VideoActivePlayerStats />
          </>
        }
        video={
          <VideoPlayer
            autoPlay
            playsInline
            skippingSeconds={VIDEO_PAGE_SKIPPING_SECONDS}
            style={{
              width: "100%",
              height: "100%",
            }}
            src={data?.game?.videoHlsUrl}
            videoRef={mergeRefs(videoRef, videoSize.ref)}
            onAudioEnabledChange={audioEnabledSet}
            onDurationChange={() => {
              durationSet(videoRef.current.duration);
            }}
            onTimeUpdateUnthrottled={() => {
              if (videoRef.current) currentTimeSet(videoRef.current.currentTime);
            }}
            onPlay={() => {
              playingSet(true);
            }}
            onPause={() => {
              playingSet(false);
            }}
            onWaiting={() => {
              bufferingSet(true);
            }}
            onCanPlay={() => {
              bufferingSet(false);
            }}
          />
        }
        floatingControls={
          <>
            <VideoSkipControl />
            <VideoNewHighlightButtons game={data?.game} />
          </>
        }
        rightFloatingControls={
          <>
            <VideoShortcutsButton />
            <VideoTourButton game={data?.game} />
          </>
        }
        highlightHub={<HighlightHub game={data?.game} />}
        controls={<VideoControls game={data?.game} />}
        listHeader={
          <>
            <VideoPotwCompetitionButton gameId={gameId} />
            <VideoBoxScoreButton gameId={gameId} />
            <VideoEventsFilter game={data?.game} />
          </>
        }
        listContent={<VideoEvents game={data?.game} />}
      />
    </videoPageContext.Provider>
  );
}
