// These are currently provision via ember_app/app/styles/fonts.sass

// Standard Arial-like font optimised for text readability. Use as default font and longer text content.
export const FONT_FAMILY_ROBOTO = "Roboto";

// Bold Gothic font with stylish look, always used with uppercase text (text-transform: uppercase). Use for higher-level headings and titles. E.g page titles, team titles.
export const FONT_FAMILY_LEAGUE_GOTHIC = "'League Gothic Variable', sans-serif";

// Less bold Gothic font. Used for lower-level or functional headings.
export const FONT_FAMILY_PATHWAY_GOTHIC_ONE = "'Pathway Gothic One', sans-serif";

export const TEXT_STYLE_TEAM_NAME = {
  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
  textTransform: "uppercase",
};

export const TEXT_STYLE_EVENT_SHORT_NAME = {
  fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
  textTransform: "uppercase",
};
