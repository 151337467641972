import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import TextWithTooltip from "atoms/TextWithTooltip";
import { getStatColor } from "helpers/colors";
import { FONT_FAMILY_ROBOTO, TEXT_STYLE_EVENT_SHORT_NAME, TEXT_STYLE_TEAM_NAME } from "helpers/FONT";
import useData from "helpers/useData";
import Section, { SectionLabel } from "molecules/Section";
import StatsTable, {
  StatsTableHeaderCell,
  StatsTableRow,
  StatsTableStatCell,
  StatsTableStatCellFragment,
} from "molecules/StatsTable";
import StatValue, { StatValueFragment } from "molecules/StatValue";
import React, { useState } from "react";

export default function BoxScore({ gameId }) {
  const [data] = useData(
    gql`
      query BoxScore($gameId: ID!) {
        game(id: $gameId) {
          currentUserDefaultGamePlayer {
            id
            team {
              id
            }
          }
          participations {
            id
            team {
              id
              name
            }
            overviewStats {
              shortName
              name
              ...StatValueFragment
            }
            gamePlayers {
              id
              playerNumber
              person {
                id
                fullNameWithAnonymisation
              }
              boxScoreStats {
                shortName
                name
                ...StatsTableStatCellFragment
              }
            }
            boxScoreTotalStats {
              shortName
              name
              ...StatsTableStatCellFragment
            }
          }
        }
      }
      ${StatValueFragment}
      ${StatsTableStatCellFragment}
    `,
    { gameId },
  );

  const [teamId, teamIdSet] = useState();
  const currentParticipation = teamId
    ? data?.game?.participations.find((participation) => participation.team.id === teamId)
    : data?.game?.participations.find(
        (participation) => participation.team.id === data?.game.currentUserDefaultGamePlayer?.team.id,
      );
  const firstRow = currentParticipation?.gamePlayers[0];

  return (
    <Section
      title="Box Score"
      labels={
        <>
          {data?.game?.participations.map((participation) => (
            <SectionLabel
              key={participation.id}
              label={participation.team.name}
              selected={currentParticipation?.id === participation.id}
              onClick={() => teamIdSet(participation.team.id)}
            />
          ))}
        </>
      }
    >
      <Stack horizontal alignItemsCenter>
        <Stack dense>
          <div
            style={{
              ...TEXT_STYLE_TEAM_NAME,
              fontSize: "1.5em",
            }}
          >
            {currentParticipation?.team.name}
          </div>
          <div style={{ opacity: 0.5 }}>Summary</div>
        </Stack>
        <Stack horizontal>
          {currentParticipation?.overviewStats.map((stat) => (
            <Stack veryDense alignItemsCenter key={stat.shortName}>
              <div
                style={{
                  ...TEXT_STYLE_EVENT_SHORT_NAME,
                  color: getStatColor(stat.name),
                }}
              >
                {stat.shortName}
              </div>
              <div
                style={{
                  fontFamily: FONT_FAMILY_ROBOTO,
                }}
              >
                <StatValue stat={stat} />
              </div>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <StatsTable
        headerColumns={["Players"]}
        columns={firstRow?.boxScoreStats.map((columnStat) => (
          <TextWithTooltip tooltip={columnStat.name} key={columnStat.shortName}>
            {columnStat.shortName}
          </TextWithTooltip>
        ))}
      >
        {currentParticipation?.gamePlayers.map((gamePlayer) => (
          <StatsTableRow key={gamePlayer.id}>
            <StatsTableHeaderCell>
              {gamePlayer.person.fullNameWithAnonymisation} - #{gamePlayer.playerNumber || "?"}
            </StatsTableHeaderCell>
            {gamePlayer.boxScoreStats.map((stat) => (
              <StatsTableStatCell key={stat.shortName} stat={stat} />
            ))}
          </StatsTableRow>
        ))}
        <StatsTableRow>
          <StatsTableHeaderCell>(Total)</StatsTableHeaderCell>
          {currentParticipation?.boxScoreTotalStats.map((stat) => (
            <StatsTableStatCell key={stat.shortName} stat={stat} />
          ))}
        </StatsTableRow>
      </StatsTable>
    </Section>
  );
}
