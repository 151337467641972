import { ButtonBase } from "@mui/material";
import Stack from "atoms/Stack";
import { FileTableOutline } from "mdi-material-ui";
import Dialog from "molecules/Dialog";
import BoxScore from "pages/games/BoxScore";
import React, { useState } from "react";

export default function VideoBoxScoreButton({ gameId }) {
  const [open, openSet] = useState(false);

  return (
    <>
      <Stack padding dense horizontal alignItemsCenter element={<ButtonBase onClick={() => openSet(true)} />}>
        <FileTableOutline />
        <div>Box Score</div>
      </Stack>
      <Dialog open={open} onClose={() => openSet(false)} mediumWidth>
        <BoxScore gameId={gameId} />
      </Dialog>
    </>
  );
}
