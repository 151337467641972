import { gql } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { PageContentStack } from "atoms/Stack";
import { getStatColor } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import Section from "molecules/Section";
import React from "react";
import { TeamPageTitle } from "shared/PageTitle";

import TeamPagesHeader from "./team/TeamPagesHeader";
import TeamSeasonSelectSubnav from "./team/TeamSeasonSelectSubnav";

export default function TeamPerformancePage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [data] = useData(
    gql`
      query TeamPerformancePage($teamId: ID!, $seasonId: ID) {
        team(id: $teamId) {
          id
          name
          performanceSnapshotRows(seasonId: $seasonId) {
            title
            avgCell {
              value
              gamesCount
              people {
                id
                fullNameWithAnonymisation
              }
            }
            totalCell {
              value
              gamesCount
              people {
                id
                fullNameWithAnonymisation
              }
            }
            highCell {
              value
              people {
                id
                fullNameWithAnonymisation
              }
            }
          }
        }
      }
    `,
    { teamId, seasonId },
  );

  return (
    <>
      <TeamPageTitle title="Performance" />
      <TeamPagesHeader teamId={teamId} tab="performance" />
      <TeamSeasonSelectSubnav teamId={teamId} seasonId={seasonId} seasonIdSet={seasonIdSet} allowAllSeasons />
      <PageContentStack>
        <Section title="Performance Snapshot">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="head"></TableCell>
                  <TableCell variant="head" align="center">
                    Average
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Total
                  </TableCell>
                  <TableCell variant="head" align="center">
                    Team High
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.team.performanceSnapshotRows.map((row) => (
                  <TableRow key={row.title}>
                    <TableCell
                      style={{
                        fontSize: "2em",
                        color: getStatColor(row.title),
                        fontWeight: "bold",
                        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                        textTransform: "uppercase",
                      }}
                    >
                      {row.title}
                    </TableCell>
                    <PerformanceSnapshotCell cell={row.avgCell} />
                    <PerformanceSnapshotCell cell={row.totalCell} />
                    <PerformanceSnapshotCell cell={row.highCell} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      </PageContentStack>
    </>
  );
}

function PerformanceSnapshotCell({ cell }) {
  return (
    <TableCell align="center">
      <div style={{ fontSize: "1.5em" }}>{[null, undefined].includes(cell?.value) ? "-" : cell?.value}</div>
      {cell && (
        <div style={{ fontSize: ".8em" }}>
          by {cell.people.map((p) => p.fullNameWithAnonymisation).join(" and ")} over {cell.gamesCount} games
        </div>
      )}
    </TableCell>
  );
}
