import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_BLACK, COLOR_WHITE, COLOR_WHITE_DARKER, COLOR_WHITE_LIGHTER } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC, FONT_FAMILY_ROBOTO } from "helpers/FONT";
import { SPACING_DENSE } from "helpers/spacings";
import usePseudoStyle from "helpers/usePseudoStyle";
import { defer } from "lodash-es";
import React from "react";

export default function StatsTable({ headerColumns, color = COLOR_WHITE, columns, children }) {
  return (
    <Paper
      style={{
        overflow: "auto",
        maxHeight: `calc(100vh - 100px)`,
      }}
    >
      <table
        style={{
          fontSize: 14,
          width: "100%",
          borderSpacing: 0,
          borderCollapse: "separate",
          whiteSpace: "nowrap",
        }}
      >
        <thead>
          <tr
            style={{
              color: COLOR_WHITE,
              backgroundColor: COLOR_BLACK,
              fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
              textTransform: "uppercase",
            }}
          >
            {headerColumns?.map((column, columnIndex) => (
              <th
                key={columnIndex}
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "inherit",
                  ...(columnIndex === 0 && {
                    left: 0,
                    zIndex: 1,
                  }),
                  textAlign: "start",
                  padding: SPACING_DENSE,
                }}
              >
                {column}
              </th>
            ))}
            {columns?.map((column, columnIndex) => (
              <th
                key={columnIndex}
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "inherit",
                  ...(columnIndex === 0 && {
                    color,
                  }),
                  textAlign: "end",
                  padding: SPACING_DENSE,
                }}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Paper>
  );
}

export function StatsTableRow({ children, highlighted = false }) {
  return (
    <tr
      style={{
        color: COLOR_BLACK,
        backgroundColor: COLOR_WHITE_LIGHTER,
        ...(highlighted && {
          backgroundColor: COLOR_WHITE,
        }),
      }}
    >
      {children}
    </tr>
  );
}

export function StatsTableButtonRow({ children }) {
  return (
    <tr>
      <td
        colSpan={99}
        style={{
          padding: 0,
          borderTop: `1px solid ${COLOR_WHITE_DARKER}`,
        }}
      >
        {children}
      </td>
    </tr>
  );
}

export function StatsTableHeaderCell({ children, color }) {
  const { className } = usePseudoStyle({
    ":first-child": `
      position: sticky;
      left: 0;
      background-color: inherit;
    `,
  });

  return (
    <td
      className={className}
      style={{
        width: 100,
        borderTop: `1px solid ${COLOR_WHITE_DARKER}`,
        borderRight: `1px solid ${COLOR_WHITE_DARKER}`,
        color,
        padding: `0 ${SPACING_DENSE}`,
      }}
    >
      {children}
    </td>
  );
}

export const StatsTableStatCellFragment = gql`
  fragment StatsTableStatCellFragment on Stat {
    statType
    value
    made
    total
  }
`;

export function StatsTableStatCell({ stat, tooltip }) {
  return (
    <StatsTableCell>
      <WithTooltip tooltip={tooltip}>
        {(() => {
          if (stat?.statType === "simple") return <>{stat.value}</>;
          if (stat?.statType === "percentage") {
            const { made, total } = stat;
            const percentage = total > 0 ? Math.round((made * 100) / total) : 0;
            return (
              <div>
                <div>
                  {made} - {total}
                </div>
                <div
                  style={{
                    fontSize: "0.7em",
                  }}
                >
                  {percentage}%
                </div>
              </div>
            );
          }

          if (stat) {
            defer(() => {
              throw new Error(`Unknown stat type: ${stat.statType}`);
            });
            return null;
          }
        })()}
      </WithTooltip>
    </StatsTableCell>
  );
}

export function StatsTableCell({ children }) {
  return (
    <td
      style={{
        borderTop: `1px solid ${COLOR_WHITE_DARKER}`,
        padding: `0 ${SPACING_DENSE}`,
        height: 30,
        textAlign: "end",
        fontFamily: FONT_FAMILY_ROBOTO,
      }}
    >
      {children}
    </td>
  );
}
