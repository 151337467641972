import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import { COLOR_PRIMARY_DARKER } from "helpers/colors";
import { SPACING_DENSE, SPACING_VERY_DENSE } from "helpers/spacings";
import EventAvatar, { EventAvatarFragment } from "molecules/EventAvatar";
import EventSubjectLabel, { EventSubjectLabelFragment } from "molecules/EventSubjectLabel";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext } from "react";

import EventActions, { EventActionsFragment } from "./EventActions";
import HighlightHubPrompts, { HighlightHubPromptsFragment } from "./HighlightHubPromps";
import VideoEventPotwRating, { VideoEventPotwRatingFragment } from "./VideoEventPotwRating";

export const HighlightHubFragment = gql`
  fragment HighlightHubFragment on Game {
    id
    ...VideoEventPotwRatingFragment
    ...EventActionsFragment
    ...HighlightHubPromptsFragment
    events {
      id
      eventView {
        id
        ...EventAvatarFragment
        humanName
      }
      ...EventSubjectLabelFragment
    }
  }
  ${EventSubjectLabelFragment}
  ${EventActionsFragment}
  ${EventAvatarFragment}
  ${VideoEventPotwRatingFragment}
  ${HighlightHubPromptsFragment}
`;

export default function HighlightHub({ game }) {
  const { activeEventId } = useContext(videoPageContext);

  const activeEvent = game?.events.find((event) => event.id === activeEventId);

  if (!activeEventId) {
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <Stack
          horizontal
          alignItemsCenter
          dense
          padding
          style={{
            borderRadius: SPACING_DENSE,
            backgroundColor: COLOR_PRIMARY_DARKER,
          }}
          data-test-highlight-hub
          data-video-joyride="highlight-bar"
        >
          {activeEvent && (
            <>
              <EventAvatar size={32} inverse eventView={activeEvent?.eventView} />
              <Stack
                veryDense
                style={{
                  fontSize: 12,
                  flex: "1 1 auto",
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  <EventSubjectLabel event={activeEvent} />
                </div>
                <div style={{ opacity: 0.6, textTransform: "uppercase" }}>{activeEvent?.eventView.humanName}</div>
              </Stack>
            </>
          )}
          <Stack veryDense horizontal alignItemsCenter>
            <VideoEventPotwRating game={game} eventId={activeEventId} />
            <EventActions game={game} eventId={activeEventId} />
          </Stack>
        </Stack>
        <Stack
          horizontal
          dense
          padding
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: `calc(100% - ${SPACING_DENSE} * 2 + ${SPACING_VERY_DENSE})`,
            // negate padding (DENSE), margin (DENSE), then add back tiny padding (VERY_DENSE)
            zIndex: -1,
          }}
        >
          <HighlightHubPrompts game={game} />
        </Stack>
      </div>
    </>
  );
}
