import KeyPressLabel from "atoms/KeyPressLabel";
import Stack from "atoms/Stack";
import { VIDEO_PAGE_SKIPPING_SECONDS } from "helpers/constants";
import {
  SHORTCUT_KEY_CODE_SHOW_SHORTCUTS,
  SHORTCUT_KEY_LABEL_SHOW_SHORTCUTS,
  SHORTCUT_KEY_LABEL_VIDEO_NEXT_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_ADD_TO_PLAYLIST,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_EDIT_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_REACTION,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_STAT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_SHARE_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_PAGE_TOGGLE_STAR,
  SHORTCUT_KEY_LABEL_VIDEO_PLAY_PAUSE,
  SHORTCUT_KEY_LABEL_VIDEO_PREVIOUS_HIGHLIGHT,
  SHORTCUT_KEY_LABEL_VIDEO_SKIP_BACKWARD,
  SHORTCUT_KEY_LABEL_VIDEO_SKIP_FORWARD,
  SHORTCUT_KEY_LABEL_VIDEO_SLOW_DOWN,
  SHORTCUT_KEY_LABEL_VIDEO_SPEED_UP,
} from "helpers/SHORTCUTS";
import useDOMEvent from "helpers/useDOMEvent";
import { Keyboard } from "mdi-material-ui";
import Dialog from "molecules/Dialog";
import Section from "molecules/Section";
import PlayerButton from "pages/video_player/PlayerButton";
import React, { useState } from "react";

export default function VideoShortcutsButton() {
  const [open, openSet] = useState(false);

  useDOMEvent("keydown", (event) => {
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
    if (event.code === SHORTCUT_KEY_CODE_SHOW_SHORTCUTS) {
      openSet(!open);
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  });

  return (
    <>
      <PlayerButton
        title="Keyboard shortcuts"
        shortcutLabel={SHORTCUT_KEY_LABEL_SHOW_SHORTCUTS}
        icon={<Keyboard />}
        onClick={() => {
          openSet(true);
        }}
      />
      <Dialog open={open} onClose={() => openSet(false)}>
        <Section title="Video playback">
          <Stack dense>
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PLAY_PAUSE} description="Play/pause" />
            <ShortcutLine
              char={SHORTCUT_KEY_LABEL_VIDEO_SKIP_BACKWARD}
              description={`Back ${VIDEO_PAGE_SKIPPING_SECONDS} seconds`}
            />
            <ShortcutLine
              char={SHORTCUT_KEY_LABEL_VIDEO_SKIP_FORWARD}
              description={`Forward ${VIDEO_PAGE_SKIPPING_SECONDS} seconds`}
            />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_SLOW_DOWN} description="Slow down" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_SPEED_UP} description="Speed up" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_NEXT_HIGHLIGHT} description="Jump to next highlight" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PREVIOUS_HIGHLIGHT} description="Jump to previous highlight" />
          </Stack>
        </Section>
        <Section title="Current highlight">
          <Stack dense>
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_EDIT_HIGHLIGHT} description="Edit current highlight" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_SHARE_HIGHLIGHT} description="Share current highlight" />
            <ShortcutLine
              char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_DOWNLOAD_HIGHLIGHT}
              description="Download current highlight"
            />
            <ShortcutLine
              char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_TOGGLE_STAR}
              description="Star/unstar current highlight"
            />
            <ShortcutLine
              char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_ADD_TO_PLAYLIST}
              description="Add current highlight to playlist"
            />
          </Stack>
        </Section>
        <Section title="Others">
          <Stack dense>
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_STAT} description="Add stat highlight" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_VIDEO_PAGE_NEW_REACTION} description="Add reaction" />
            <ShortcutLine char={SHORTCUT_KEY_LABEL_SHOW_SHORTCUTS} description="Toggle keyboard shortcuts list" />
          </Stack>
        </Section>
      </Dialog>
    </>
  );
}

function ShortcutLine({ char, description }) {
  return (
    <Stack veryDense horizontal alignItemsCenter style={{ fontSize: 14 }}>
      <KeyPressLabel>{char}</KeyPressLabel>
      <div>{description}</div>
    </Stack>
  );
}
